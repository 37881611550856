import { MiddlewareError } from "@/middleware/middlewareError"
import { LoginRedirect } from "@/repositories/authenticationRepository/types"
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router"

export default function guard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    return async function (
        guardFunctions: Array<(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => Promise<unknown>>,
    ) {
        for (const guardFunction of guardFunctions) {
            try {
                await guardFunction(to, from, next)
            } catch (error) {
                if (error instanceof MiddlewareError) {
                    console.error(error.message)
                    return next(error.redirectUrl)
                }
                if (error instanceof LoginRedirect) {
                    return next()
                }
                console.error(error)
                return next("/error")
            }
        }

        next()
    }
}
