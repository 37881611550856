import { ref } from "vue"
import { fetchTranslationsFor } from "@/utils/translation/fetchTranslations"
import { logError } from "@/utils/translation/logging"
import { ITranslationOptions, ITranslations, TranslationNotFoundError } from "@/utils/translation/types"
import { isSupportedLanguage } from "@/utils/translation/userLocale"
import { setProfileLanguage } from "@/repositories/profileRepository"

const DEFAULT_NAMING_FALLBACK = "_"

export const translationOptions: ITranslationOptions = {
    language: "",
    supportedLanguages: {
        en: "English",
        nl: "Nederlands",
    },
    fallbackLanguage: "nl",
}

const translations = ref<ITranslations>({})

export function translate(key: string): string {
    try {
        const translation = findTranslation(key)

        if (translation === undefined) {
            throw new TranslationNotFoundError()
        }

        if (typeof translation === "object" && translation[DEFAULT_NAMING_FALLBACK] !== undefined) {
            return translation[DEFAULT_NAMING_FALLBACK]
        } else {
            return translation
        }
    } catch (error: any) {
        if (process.env.NODE_ENV !== "production") {
            logError(error, key)
        }

        return key.split(".")[key.split(".").length - 1]
    }
}

function findTranslation(key: string) {
    try {
        const keys = key.split(".")

        if (keys.length === 1) {
            return translations.value[keys[0]] as string
        }

        const last = keys[keys.length - 1]
        const namespace = keys.slice(0, -1)
        const foundTranslations = namespace.reduce((acc, part) => acc[part] as ITranslations, translations.value)

        return foundTranslations[last] as string
    } catch {
        throw new TranslationNotFoundError()
    }
}

export async function loadAndSetTranslations(language: string): Promise<void> {
    translations.value = await fetchTranslationsFor(language)
}

export async function setLanguage(language: string): Promise<void> {
    if (!isSupportedLanguage(language)) {
        throw new Error("Sorry we don't support that language yet.")
    }

    if (translationOptions.language === language) {
        return
    }

    try {
        await loadAndSetTranslations(language)
        await setProfileLanguage(language)
        localStorage.setItem("language", language)
        translationOptions.language = language
    } catch (error) {
        console.error(`Failed to load language: ${language}. With error: ${error}`)
    }
}
