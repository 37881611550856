import { fetchProfile, updateProfile } from "@/api/profile"
import { isSupportedLanguage } from "@/utils/translation/userLocale"
import { Profile } from "@satys/buf-contracts/satys/domain/domain_pb"
import { computed, ref } from "vue"
import { Code, ConnectError } from "@bufbuild/connect"

const _loading = ref(false)
const _profile = ref<Profile | undefined>(undefined)

export const loading = computed(() => _loading.value)

export async function setProfileLanguage(language: string): Promise<void> {
    if (!isSupportedLanguage(language)) {
        throw new Error("We do not yet support that language.")
    }

    _loading.value = true

    if (_profile.value === undefined) {
        _profile.value = new Profile()
    }

    _profile.value.locale = language

    try {
        await updateProfile(_profile.value)
    } finally {
        _loading.value = false
    }
}

export async function getProfile(): Promise<Profile | undefined> {
    if (_profile.value !== undefined) {
        return _profile.value
    }

    _loading.value = true

    try {
        const response = await fetchProfile()
        const newProfile = response.profile

        setProfile(newProfile)
        return newProfile
    } catch (error) {
        if (error instanceof ConnectError) {
            console.log({ ...error })
            if (error.code !== Code.NotFound) {
                throw error
            }
        } else {
            throw error
        }
    } finally {
        _loading.value = false
    }
}

export function setProfile(newProfile: Profile | undefined) {
    _profile.value = newProfile
}
