import { getProfile } from "@/repositories/profileRepository"
import { translationOptions } from "@/utils/translation"

export async function resolveUserLocale(): Promise<string> {
    const userLocale = normalize(await getUserLocale())
    if (isSupportedLanguage(userLocale)) {
        return userLocale
    }

    const languageCode = userLocale.split("-")[0]
    if (isSupportedLanguage(languageCode)) {
        return languageCode
    }

    return translationOptions.fallbackLanguage
}

async function getUserLocale(): Promise<string> {
    try {
        const profile = await getProfile()
        if (profile?.locale) {
            return normalize(profile.locale)
        }
    } catch {
        // Profile not available, fall back
    }
    return localStorage.getItem("language") || window.navigator.language
}

export function isSupportedLanguage(language: string): boolean {
    return Object.keys(translationOptions.supportedLanguages).includes(language)
}

function normalize(userLocale: string): string {
    userLocale = userLocale.replace("_", "-")

    if (!userLocale.includes("-")) {
        return userLocale.toLowerCase()
    }

    const [language, region] = userLocale.split("-")
    return language.toLowerCase() + "-" + region.toUpperCase()
}
