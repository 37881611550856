import { datanalysisStub } from "@/libs/stub"
import {
    GetOrganisationRolesRequest,
    GetRoleTasksResponse,
    GetUserRolesRequest,
    GetUserRolesResponse,
    SetActiveRoleRequest,
    SetActiveRoleResponse,
} from "@satys/buf-contracts/satys/datanalysis/datanalysis_pb"
import { Organisation, Role } from "@satys/buf-contracts/satys/domain/domain_pb"

export async function fetchRoles(depth = 0): Promise<GetUserRolesResponse> {
    const request = new GetUserRolesRequest({ depth })

    try {
        return await datanalysisStub.get_user_roles(request)
    } catch (error) {
        console.error("getroles error:", error)
        throw error
    }
}

export async function setActiveRole(role: Role): Promise<SetActiveRoleResponse> {
    const request = new SetActiveRoleRequest({ role })

    try {
        return await datanalysisStub.set_active_role(request)
    } catch (error) {
        console.error("setActiveRoles error: ", error)
        throw error
    }
}

export async function fetchRoleTasks(role: Role): Promise<GetRoleTasksResponse> {
    const request = new SetActiveRoleRequest({ role })

    try {
        return await datanalysisStub.get_role_tasks(request)
    } catch (error) {
        console.log("getRoleTasks error: ", error)
        throw error
    }
}

export async function fetchRolesByOrganisation(organisation: Organisation) {
    const request = new GetOrganisationRolesRequest({ organisation })

    try {
        return await datanalysisStub.get_organisation_roles(request)
    } catch (error) {
        console.error("fetchRolesByOrganisation error: ", error)
        throw error
    }
}
