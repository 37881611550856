<template>
    <router-view />
</template>

<script setup lang="ts">
import { modalState } from "./state/modal"
import { onMounted, onUnmounted, provide, ref } from "vue"
import { isDesktopKey, isMobileKey, isTabletKey, modalStateKey } from "@/utils/symbolKeys"
import tailwindConfigJson from "../tailwind.config"
import resolveConfig from "tailwindcss/resolveConfig"

const tailwindConfig = resolveConfig(tailwindConfigJson)

const isMobile = ref(false)
const isTablet = ref(false)
const isDesktop = ref(false)

provide(isMobileKey, isMobile)
provide(isTabletKey, isTablet)
provide(isDesktopKey, isDesktop)

provide(modalStateKey, modalState)

onMounted(() => {
    resize()
    window.addEventListener("resize", resize)
})

onUnmounted(() => {
    window.removeEventListener("resize", resize)
})

function resize() {
    // The values of theses tailwindConfig variables can be found in the tailwind.config.ts file.
    isMobile.value = window.innerWidth <= parseInt(tailwindConfig.theme.screens.mobile.max)
    isTablet.value = window.innerWidth >= parseInt(tailwindConfig.theme.screens.tablet)
    isDesktop.value = window.innerWidth >= parseInt(tailwindConfig.theme.screens.desktop)
}
</script>

<style lang="scss">
#app {
    @apply md:flex md:flex-row w-full;
}

.menu-open {
    // Safari fix: just "overflow: hidden;" doesn't work...
    @apply relative h-full overflow-y-hidden;
}
</style>
