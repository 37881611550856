import { computed, ref } from "vue"
import { createAuth0Client, Auth0Client, AuthorizationParams } from "@auth0/auth0-spa-js"
import { Organisation, Role, Task, User } from "@satys/buf-contracts/satys/domain/domain_pb"
import { fetchRoles, fetchRoleTasks } from "@/api/roles"
import { GetRoleTasksResponse, GetUserRolesResponse } from "@satys/buf-contracts/satys/datanalysis/datanalysis_pb"
import { AuthHeaders, AuthState, AuthStatus, LoginAppState, LoginRedirect, Token } from "./types"
import { taskToString } from "@/utils"
import { Code, ConnectError } from "@bufbuild/connect"
import router from "@/router"

let auth0: Auth0Client

// Local (and global) auth state. Should never directly be accessed.
const authState = ref<AuthState>({
    status: AuthStatus.UNAUTHENTICATED,
    roles: [],
    roleTasks: [],
})

// URL to profile picture provided by Auth0
const _picture = ref<string | null>(null)
export const picture = computed(() => _picture.value)

// Local tokenCache which can be used synchronously
const _syncTokenCache = ref<Token>()

/**
 * Throw a developer-friendly error when initAuth isn't properly called.
 */
function verifyInit() {
    if (!auth0) {
        throw Error("You must call and await initAuth first!")
    }
}

/**
 * Initialize the auth module.
 */
export async function initAuth(): Promise<void> {
    auth0 = await createAuth0Client({
        domain: process.env.AUTH_DOMAIN,
        clientId: process.env.AUTH_CLIENT_ID,
        cookieDomain: process.env.AUTH_COOKIE_DOMAIN,
        useRefreshTokens: true,
        authorizationParams: {
            audience: process.env.AUTH_AUDIENCE,
        },
    })
    let target: string | undefined

    try {
        target = await handleLoginCallback()
    } catch {
        // Probably the state isn't valid anymore.
        await login()
    }

    getRole()
    await getAuthStatus()

    if (target) {
        await router.push(target)
    }
}

/**
 * Handle redirect from login.
 */
async function handleLoginCallback() {
    const query = window.location.search
    if (query.includes("code=") && query.includes("state=")) {
        const {
            appState: { target },
        } = await auth0.handleRedirectCallback()
        return target
    }
}

let getUserRolesPromise: Promise<GetUserRolesResponse> | void
/**
 * Get the current user's roles from the Satys domain.
 */
export async function getUserRoles({ flushCache = false } = {}) {
    if ((!authState.value.roles.length && !getUserRolesPromise) || flushCache) {
        getUserRolesPromise = fetchRoles()
    }
    if (getUserRolesPromise) {
        try {
            const resp = await getUserRolesPromise
            authState.value.roles = resp.roles
        } finally {
            // Make sure we unset this, so it will properly redo the request when required.
            getUserRolesPromise = undefined
        }
    }
    return authState.value.roles
}
export const userRoles = computed(() => authState.value.roles)

/**
 * Set the current user's role.
 */
export function setRole(role: Role) {
    authState.value.role = role
    // Save in localstorage so we can use this across sessions
    localStorage.setItem("role", [role.organisation?.domain, role.name].join("."))
}

let getRoleTasksPromise: Promise<GetRoleTasksResponse>
// Role for which getRoleTasks result is cached
let getRoleTasksCurrentRole: Role
/**
 * Get all tasks which the current role is allowed to execute.
 */
export async function getRoleTasks() {
    const [oldRole, newRole] = [getRole(), getRoleTasksCurrentRole]
    const [oldDomain, newDomain] = [oldRole, newRole].map((r) => r?.organisation?.domain)
    const rolesEqual = newDomain === oldDomain && newRole?.name === oldRole?.name

    if (!rolesEqual || (!authState.value.roleTasks.length && !getRoleTasksPromise)) {
        getRoleTasksCurrentRole = <Role>getRole()
        getRoleTasksPromise = fetchRoleTasks(getRoleTasksCurrentRole)
    }

    if (getRoleTasksPromise) {
        const resp = await getRoleTasksPromise
        authState.value.roleTasks = resp.tasks
    }

    return authState.value.roleTasks
}
export const roleTasks = computed(() => authState.value.roleTasks)

/**
 * Get the access token. It is cached and a new one is automatically fetched
 * when there's less than 60 seconds left for the cached token.
 */
export async function getToken(opts: { forceRefresh?: boolean } = {}) {
    verifyInit()
    try {
        // auth0's getTokenSilently already caches the token and will fetch a new
        // one if there's less than 60 seconds left.
        const { expires_in: _expiresIn, ...token } = await auth0.getTokenSilently({
            detailedResponse: true,
            cacheMode: opts.forceRefresh ? "off" : "on",
        })
        _syncTokenCache.value = token

        // If no token provided, throw an error so login is called.
        if (!token.access_token) {
            throw Error("No access token")
        }

        return <Token>token
    } catch {
        await login()
    }
}
export const token = computed(() => _syncTokenCache.value)

// Prevent login to be run twice
let isLoggingIn = false

/**
 * Login (redirects).
 */
export async function login(opts?: AuthorizationParams & { screen_hint: string }) {
    verifyInit()
    if (isLoggingIn) {
        throw new LoginRedirect()
    }
    isLoggingIn = true
    await auth0.loginWithRedirect<LoginAppState>({
        appState: {
            target: window.location.pathname + window.location.search,
        },
        authorizationParams: {
            redirect_uri: window.location.origin,
            ...opts,
        },
    })
    throw new LoginRedirect()
}

/**
 * Redirects to sign up page.
 */
export async function signUp() {
    return await login({ screen_hint: "signup" })
}

/**
 * Logout (redirects).
 */
export async function logout() {
    verifyInit()
    localStorage.removeItem("role")
    return await auth0.logout({
        logoutParams: {
            returnTo: window.location.origin,
        },
    })
}

/**
 * Get the current user.
 */
export async function getUser() {
    verifyInit()
    if (!authState.value.user) {
        const auth0User = await auth0.getUser()

        if (!auth0User?.email) {
            return undefined
        }

        const user = new User({
            emailAddress: auth0User.email,
        })
        authState.value.user = user

        _picture.value = auth0User.picture
    }
    return authState.value.user
}
export const user = computed(() => authState.value.user)

/**
 * Get the current role.
 */
export function getRole() {
    if (!authState.value.role) {
        // Try to get current role from localstorage
        const roleString = localStorage.getItem("role")
        if (!roleString) {
            return
        }
        const [orgDomain, roleName] = roleString.split(".", 2)

        const role = new Role({
            name: roleName,
            organisation: new Organisation({
                domain: orgDomain,
            }),
        })

        authState.value.role = role
    }
    return authState.value.role
}
export const role = computed(() => authState.value.role)

/**
 * Smartly get authorization headers and refresh token when expired.
 */
export async function getAuthMetadata() {
    const token = await getToken()
    return _getAuthMetadata(token, getRole())
}

/**
 * Get authorization headers based on loaded state.
 */
export function getAuthMetadataSync() {
    return _getAuthMetadata(_syncTokenCache.value, getRole())
}

function _getAuthMetadata(token?: Token, role?: Role) {
    const metadata: AuthHeaders = {}
    if (token?.access_token) {
        metadata.authorization = `Bearer ${token.access_token}`
    }
    if (role) {
        metadata.role = role.name
        metadata.organisation = role.organisation?.domain
    }
    return metadata
}

/**
 * Check how the current user is authenticated.
 */
export async function getAuthStatus(): Promise<AuthStatus> {
    verifyInit()
    const auth0User = await auth0.getUser()
    if (!auth0User) {
        authState.value.status = AuthStatus.UNAUTHENTICATED
        return authState.value.status
    }
    if (!auth0User.email_verified) {
        authState.value.status = AuthStatus.NOT_VERIFIED
        return authState.value.status
    }
    try {
        await getUserRoles()
        authState.value.status = AuthStatus.HAS_ROLES
    } catch (e) {
        if (e instanceof ConnectError) {
            const failStatuses = [Code.Unknown, Code.Unavailable, Code.Internal]
            if (failStatuses.includes(e.code)) {
                throw e
            } else {
                authState.value.status = AuthStatus.NO_ROLES
                return authState.value.status
            }
        }
        throw e
    }
    if (getRole()) {
        authState.value.status = AuthStatus.ACTIVE_ROLE
        return authState.value.status
    }
    return authState.value.status
}
export const authStatus = computed(() => authState.value.status)

/**
 * Check whether current role is authorized for given task.
 */
export async function isAuthorizedFor(task: Task | string) {
    try {
        const roleTasks = await getRoleTasks()
        return _isAuthorizedFor(roleTasks, task)
    } catch (e) {
        console.warn(`Failed to check authorization: ${e}`)
        return false
    }
}

function _isAuthorizedFor(roleTasks: Task[] = [], task: Task | string) {
    if (task instanceof Task) {
        task = taskToString(task)
    }
    return roleTasks.some((_task) => taskToString(_task) === task)
}
