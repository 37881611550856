export class InvalidTranslationFallbackError extends Error {}
export class TranslationNotFoundError extends Error {}

export interface ITranslations {
    [k: string]: ITranslations | string
}

export interface ITranslationOptions {
    language: string
    supportedLanguages: Record<string, string>
    fallbackLanguage: string
}
