import { App } from "vue"
import { translate } from "@/utils/translation"

declare module "@vue/runtime-core" {
    interface ComponentCustomProperties {
        t: (key: string) => string
    }
}

const i18n = {
    install: (app: App) => {
        app.config.globalProperties.t = translate
    },
}

export default i18n
