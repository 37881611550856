import { InvalidTranslationFallbackError, TranslationNotFoundError } from "@/utils/translation/types"

const MISSING_TRANSLATIONS: string[] = []

export function logError(error: Error, key?: string): void {
    if (error instanceof InvalidTranslationFallbackError) {
        console.warn(error)
        return
    }

    if (error instanceof TranslationNotFoundError) {
        // Prevent missing translations from being logged multiple times
        if (!MISSING_TRANSLATIONS.includes(`${key}`)) {
            console.warn(`Could not find translation for "${key}". Make sure the translation has been added.`)
            MISSING_TRANSLATIONS.push(`${key}`)
        }
        return
    }

    console.warn(error)
}
