import { ref, readonly, Component, markRaw } from "vue"

interface ModalContent {
    component: Component
    props?: Record<string, any>
}

export interface ModalState {
    showModal: boolean
    modalContent: ModalContent | null
    openModal: (component: ModalContent["component"], props?: ModalContent["props"]) => void
    closeModal: () => void
}

const showModal = ref(false)
const modalContent = ref<ModalContent | null>(null)

function openModal(component: ModalContent["component"], props?: ModalContent["props"]) {
    modalContent.value = {
        component: markRaw(component),
        props: props,
    }
    showModal.value = true
}

function closeModal() {
    showModal.value = false
    setTimeout(() => {
        modalContent.value = {}
    }, 0)
}

export const modalState: Readonly<ModalState> = readonly({
    showModal,
    modalContent,
    openModal,
    closeModal,
})
