import * as api from "@/api/roles"
import { computed, ref } from "vue"
import { Organisation, Role } from "@satys/buf-contracts/satys/domain/domain_pb"

const _topLevelRoles = ref<Role[]>([])
const _organistaionRoles = ref<Role[]>([])

export const topLevelRoles = computed(() => _topLevelRoles.value)
export const organisationRoles = computed(() => _organistaionRoles.value)

export async function getTopLevelRoles() {
    if (topLevelRoles.value.length) {
        return topLevelRoles.value
    }

    const depth = 1
    const response = await api.fetchRoles(depth)
    _topLevelRoles.value = response.roles

    return topLevelRoles.value
}

export async function getOrganisationRoles(organisation: Organisation) {
    const response = await api.fetchRolesByOrganisation(organisation)
    _organistaionRoles.value = response.roles
}
