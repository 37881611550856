const DEFAULT_SCALE = 5
const DEFAULT_MIN_SCALE = 1

export function scaleScore(score: number, newScale: number, minScale: number = DEFAULT_MIN_SCALE, oldScale = DEFAULT_SCALE) {
    if (!score) {
        return 0
    }
    const oldRange = oldScale - 1
    const newRange = newScale - minScale
    return ((score - 1) * newRange) / oldRange + minScale
}

type truncateAndScaleScoreOptions = {
    score: number
    newScale: number
    oldScale?: number
    minScale?: number
    digits?: number
}

export function truncateAndScaleScore({ score, newScale, oldScale, minScale, digits = 2 }: truncateAndScaleScoreOptions) {
    return truncateDecimalDigits(scaleScore(score, newScale, minScale, oldScale), digits)
}

export function truncateDecimalDigits(value: number, digits = 2) {
    return Number(
        value.toLocaleString("en", {
            minimumFractionDigits: 0,
            maximumFractionDigits: digits,
        }),
    )
}
