import { setRole, getUserRoles } from "@/repositories/authenticationRepository"
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router"
import { MiddlewareError } from "./middlewareError"

export default async function resolveRoleFromUrl(to: RouteLocationNormalized, _from: RouteLocationNormalized, _next: NavigationGuardNext) {
    try {
        const roles = await getUserRoles()

        const requestedDomain = to.params.domainName as string
        const role = roles.find((r) => r.organisation?.domain === requestedDomain)

        if (role === undefined) {
            throw new MiddlewareError("No user role found for domain: " + requestedDomain, "/manage")
        }

        setRole(role)
    } catch (error) {
        throw new MiddlewareError("Cannot get user roles.", "/error")
    }
}
