import { PerformanceIndicator, PerformanceIndicator_Type, Product, Process, Task } from "@satys/buf-contracts/satys/domain/domain_pb"
import { scaleScore } from "./scaleScore"
import { translate } from "@/utils/translation"

/**
 * Create a human readable string based on a score and performance indicator.
 * E.g. a CSAT score will be converted into a percentage-string.
 */
export function stringifyScore(performanceIndicator: PerformanceIndicator, score: number, scale: number): string {
    const type = performanceIndicator.type

    if (type === PerformanceIndicator_Type.UNSPECIFIED) {
        throw Error("Unspecified performance indicator type.")
    }

    return {
        [PerformanceIndicator_Type.AVERAGE]: String(scaleScore(score, scale, performanceIndicator.minScale).toFixed(1)),
        [PerformanceIndicator_Type.CSAT]: `${(score * 100).toFixed(0)}%`,
        [PerformanceIndicator_Type.NPS]: String(score.toFixed(0)),
    }[type]
}

/**
 * Convert given task into a "product.process.task" string.
 */
export function taskToString(task: Task): string {
    const process = task.process
    const product = process?.product
    return `${product?.name}.${process?.name}.${task.name}`.toLocaleLowerCase()
}

/**
 * Get domain.Task based on view path (within the "views" folder)
 */
export function getTaskByView(path: string) {
    return taskFromString(path.split("views").pop() || "")
}

/**
 * Convert a product.process.task (or product/process/task) string
 * into a domain.Task object.
 */
export function taskFromString(taskString: string) {
    const [productName, processName, taskName] = taskString.split(/\.|\//, 3).filter(Boolean)

    return new Task({
        name: taskName,
        process: new Process({
            name: processName,
            product: new Product({ name: productName }),
        }),
    })
}

export function translatePerformanceIndicator(performanceIndicator: PerformanceIndicator) {
    const [key] = Object.entries(PerformanceIndicator_Type).find(([_, value]) => value === performanceIndicator.type)

    return key ? translate("performanceIndicator." + key) : "-"
}
