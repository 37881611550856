import type { Options } from "@sentry/vue/types/types"

function getApiUrl() {
    if (process.env.NODE_ENV === "development") {
        return "https://api.127.0.0.1.nip.io:31390"
    }

    if (process.env.NODE_ENV === "dev") {
        return "https://api.dev.satys.app:443"
    }

    return "https://api.satys.app:443"
}

const getSentryEnvironment = () => {
    return {
        ["manedge.satys.app"]: "development",
        ["manext.satys.app"]: "staging",
        ["manage.satys.app"]: "production",
    }[window.location.hostname]
}

type SentryOptions = Partial<Options> & {
    tracingOrigins: string[]
}

export const sentryConfig: SentryOptions = {
    dsn: "https://548d676ff099402ea46cfcf55167f340@o1151238.ingest.sentry.io/6227734",
    tracingOrigins: ["manage.satys.app"],
    tracesSampleRate: process.env.NODE_ENV === "production" ? 0.5 : 1.0,
    environment: getSentryEnvironment() ?? "development",
}

export const apiUrl = getApiUrl()
export const grpcStubOptions = { withCredentials: true }
