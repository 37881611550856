import "@/assets/css/index.scss"
import App from "@/App.vue"
import router from "@/router"
import { createApp } from "vue"
import { i18n, sentry } from "@/plugins"
import { initAuth } from "@/repositories/authenticationRepository"
import { loadAndSetTranslations, translationOptions } from "@/utils/translation"
import { resolveUserLocale } from "@/utils/translation/userLocale"
;(async function () {
    const _createApp = async () => {
        translationOptions.language = await resolveUserLocale()
        await loadAndSetTranslations(translationOptions.language || translationOptions.fallbackLanguage)
        createApp(App).use(sentry).use(router).use(i18n).mount("#app")
    }

    try {
        await initAuth()
        await _createApp()
    } catch (e) {
        console.error(e)
        await _createApp()
        await router.push("/error")
    }
})()
