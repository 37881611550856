import { apiUrl, grpcStubOptions } from "@/config"
import { createGrpcWebTransport } from "@bufbuild/connect-web"
import { AuthRequestInterceptor } from "@/libs/stub/authInterceptor"
import { createPromiseClient, Interceptor } from "@bufbuild/connect"
import { ProcessorAPI } from "@satys/buf-contracts/satys/processor/processor_connectweb"
import { DatanalysisAPI } from "@satys/buf-contracts/satys/datanalysis/datanalysis_connectweb"
import { AddQuestionnaireAPI } from "@satys/buf-contracts/satys/datanalysis/add_questionnaire_connectweb"
import { PyGrunnSatysfactionAPI } from "@satys/buf-contracts/satys/datanalysis/pygrunn_satysfaction_connectweb"

declare global {
    interface Window {
        __CONNECT_WEB_DEVTOOLS__: Interceptor
    }
}

const interceptors = [AuthRequestInterceptor]

if (process.env.NODE_ENV !== "production") {
    if (typeof window.__CONNECT_WEB_DEVTOOLS__ !== "undefined") {
        interceptors.push(window.__CONNECT_WEB_DEVTOOLS__)
    } else {
        window.addEventListener("connect-web-dev-tools-ready", () => {
            if (typeof window.__CONNECT_WEB_DEVTOOLS__ !== "undefined") {
                interceptors.push(window.__CONNECT_WEB_DEVTOOLS__)
            }
        })
    }
}

export const datanalysisStub = createPromiseClient(
    DatanalysisAPI,
    createGrpcWebTransport({
        baseUrl: apiUrl,
        interceptors,
        ...grpcStubOptions,
    }),
)

export const addQuestionnaireStub = createPromiseClient(
    AddQuestionnaireAPI,
    createGrpcWebTransport({
        baseUrl: apiUrl,
        interceptors,
        ...grpcStubOptions,
    }),
)

export const pygrunnSatysfactionStub = createPromiseClient(
    PyGrunnSatysfactionAPI,
    createGrpcWebTransport({
        baseUrl: apiUrl,
        interceptors,
        ...grpcStubOptions,
    }),
)

export const processorStub = createPromiseClient(
    ProcessorAPI,
    createGrpcWebTransport({
        baseUrl: apiUrl,
        interceptors,
        ...grpcStubOptions,
    }),
)
