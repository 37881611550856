import { App } from "vue"
import { sentryConfig } from "@/config"
import { BrowserTracing } from "@sentry/browser"
import * as Sentry from "@sentry/vue"
import router from "@/router"

const sentry = {
    install: (app: App) => {
        if (process.env.NODE_ENV === "production") {
            Sentry.init({
                app,
                dsn: sentryConfig.dsn,
                integrations: [
                    new BrowserTracing({
                        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                        tracingOrigins: sentryConfig.tracingOrigins,
                    }),
                ],
                tracesSampleRate: sentryConfig.tracesSampleRate,
                environment: sentryConfig.environment,
            })
        }
    },
}

export default sentry
