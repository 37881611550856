import { Interceptor } from "@bufbuild/connect"
import { getAuthMetadata } from "@/repositories/authenticationRepository"

export const AuthRequestInterceptor: Interceptor = (next) => async (request) => {
    const metadata = await getAuthMetadata()

    if (metadata.authorization) {
        request.header.set("authorization", metadata.authorization)
    }
    if (metadata.organisation) {
        request.header.set("organisation", metadata.organisation)
    }
    if (metadata.role) {
        request.header.set("role", metadata.role)
    }

    return await next(request)
}
