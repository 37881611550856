import { datanalysisStub } from "@/libs/stub"
import {
    GetProfileRequest,
    GetProfileResponse,
    SetProfileRequest,
    SetProfileResponse,
} from "@satys/buf-contracts/satys/datanalysis/datanalysis_pb"
import { Profile } from "@satys/buf-contracts/satys/domain/domain_pb"

export async function fetchProfile(): Promise<GetProfileResponse> {
    const request = new GetProfileRequest()

    try {
        return await datanalysisStub.get_profile(request)
    } catch (error) {
        console.error("fetchProfile error: ", error)
        throw error
    }
}

export async function updateProfile(profile: Profile): Promise<SetProfileResponse> {
    const request = new SetProfileRequest({ profile })

    try {
        return await datanalysisStub.set_profile(request)
    } catch (error) {
        console.error("updateProfile error: ", error)
        throw error
    }
}
