import App from "@/App.vue"
import resolveRoleFromUrl from "@/middleware/resolveRoleFromUrl"
import { authStatus, getRole, login, logout, role, setRole } from "@/repositories/authenticationRepository"
import { AuthStatus } from "@/repositories/authenticationRepository/types"
import { getTopLevelRoles } from "@/repositories/roleRepository"
import guard from "@/router/guard"
import { translate as t } from "@/utils/translation"
import { capitalize, nextTick } from "vue"
import "vue-router"
import { RouteLocationNormalized, RouteRecordRaw, createRouter, createWebHistory } from "vue-router"

// Overwrite RouteMeta type
declare module "vue-router" {
    interface RouteMeta {
        auth?: AuthStatus
        title?: (to: RouteLocationNormalized) => string
        breadcrumbs?: (currentRoute: RouteLocationNormalized) => Array<{ name: string; path?: string }>
    }
}

const DEFAULT_TITLE = "SatysManage"
const TITLE_PREFIX = "SatysManage - "

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        redirect: "/manage",
        component: App,
        meta: {
            auth: AuthStatus.HAS_ROLES,
            breadcrumbs: () => [{ name: t("breadcrumbs.home") }],
        },
        children: [
            {
                path: "manage",
                redirect: "/manage/organisations/",
                component: () => import("@/layouts/ManageLayout.vue"),
                meta: {
                    auth: AuthStatus.ACTIVE_ROLE,
                    breadcrumbs: () => [{ name: t("breadcrumbs.manage") }],
                },
                children: [
                    {
                        path: "organisations",
                        component: () => import("@/views/manage/organisations/OrganisationList.vue"),
                        meta: {
                            breadcrumbs() {
                                return [{ name: t("breadcrumbs.manage"), path: "/manage/" }, { name: t("organisation.plural") }]
                            },
                        },
                    },
                    {
                        path: "organisations/:domainName",
                        component: () => import("@/views/manage/organisations/DetailPage.vue"),
                        beforeEnter: async (to, from, next) => {
                            await guard(to, from, next)([resolveRoleFromUrl])
                        },
                        meta: {
                            title: () => TITLE_PREFIX + role.value.organisation.name,
                            breadcrumbs() {
                                return [
                                    { name: t("breadcrumbs.manage"), path: "/manage/" },
                                    { name: t("organisation.plural"), path: "/manage/organisations/" },
                                    { name: role.value.organisation.name },
                                ]
                            },
                        },
                    },
                    {
                        path: "organisations/:domainName/measurements/:measurementName",
                        component: () => import("@/views/manage/organisations/measurements/OverviewPage.vue"),
                        beforeEnter: async (to, from, next) => {
                            await guard(to, from, next)([resolveRoleFromUrl])
                        },
                        meta: {
                            title: (to: RouteLocationNormalized) =>
                                `${TITLE_PREFIX}${role.value.organisation.name} - ${to.params.measurementName}`,
                            breadcrumbs(currentRoute: RouteLocationNormalized) {
                                return [
                                    { name: t("breadcrumbs.manage"), path: "/manage/" },
                                    { name: t("organisation.plural"), path: "/manage/organisations/" },
                                    {
                                        name: role.value.organisation.name,
                                        path: `/manage/organisations/${role.value.organisation.domain}/`,
                                    },
                                    { name: t("measurement.plural") },
                                    { name: currentRoute.params.measurementName as string },
                                ]
                            },
                        },
                    },
                    {
                        path: "organisations/:domainName/measurements/:measurementName/questionnaires/:questionnaireName",
                        component: () => import("@/views/manage/organisations/measurements/questionnaires/OverviewPage.vue"),
                        beforeEnter: async (to, from, next) => {
                            await guard(to, from, next)([resolveRoleFromUrl])
                        },
                        meta: {
                            title: (to: RouteLocationNormalized) => {
                                return `${TITLE_PREFIX}${to.params.questionnaireName} - ${to.params.measurementName} - ${role.value.organisation.name}`
                            },
                            breadcrumbs(currentRoute: RouteLocationNormalized) {
                                return [
                                    { name: t("breadcrumbs.manage"), path: "/manage/" },
                                    { name: t("organisation.plural"), path: "/manage/organisations/" },
                                    {
                                        name: role.value.organisation.name,
                                        path: `/manage/organisations/${role.value.organisation.domain}/`,
                                    },
                                    { name: t("measurement.plural") },
                                    {
                                        name: currentRoute.params.measurementName as string,
                                        path: `/manage/organisations/${role.value.organisation.domain}/measurements/${currentRoute.params.measurementName}/`,
                                    },
                                    { name: t("questionnaire.plural") },
                                    { name: currentRoute.params.questionnaireName as string },
                                ]
                            },
                        },
                    },
                    {
                        path: ":domainName/:productName/:processName/",
                        component: () => import("@/views/manage/process/ProcessHandler.vue"),
                        beforeEnter: async (to, from, next) => {
                            await guard(to, from, next)([resolveRoleFromUrl])
                        },
                        meta: {
                            title: (to: RouteLocationNormalized) => {
                                return `${TITLE_PREFIX}${role.value.organisation.name}: ${to.params.productName} > ${to.params.processName}`
                            },
                            breadcrumbs(currentRoute: RouteLocationNormalized) {
                                return [
                                    { name: t("breadcrumbs.manage"), path: "/manage/" },
                                    { name: role.value.organisation.name },
                                    { name: t(normalizeProcessName(currentRoute.params.processName as string)) },
                                ]
                            },
                        },
                    },
                    {
                        path: ":domainName/:productName/:processName/:taskName",
                        component: () => import("@/views/PreviewComponent.vue"),
                        beforeEnter: async (to, from, next) => {
                            if (process.env.NODE_ENV === "production") {
                                const { domainName, productName, processName } = to.params
                                return next({ path: `/manage/${domainName}/${productName}/${processName}`, query: to.query })
                            }
                            await guard(to, from, next)([resolveRoleFromUrl])
                        },
                        meta: {
                            title: (to: RouteLocationNormalized) => {
                                return `${TITLE_PREFIX}${role.value.organisation.name}: ${to.params.productName} > ${to.params.processName} > ${to.params.taskName}`
                            },
                            breadcrumbs(currentRoute: RouteLocationNormalized) {
                                return [
                                    { name: t("breadcrumbs.manage"), path: "/manage/" },
                                    { name: role.value.organisation.name },
                                    { name: t(normalizeProcessName(currentRoute.params.processName as string)) },
                                    { name: t(normalizeProcessName(currentRoute.params.taskName as string)) },
                                ]
                            },
                        },
                    },
                    {
                        path: "notifications",
                        component: () => import("@/views/manage/NotificationsPage.vue"),
                        meta: {
                            breadcrumbs() {
                                return [{ name: t("breadcrumbs.manage"), path: "/manage/" }, { name: t("notifications") }]
                            },
                        },
                    },
                ],
            },
        ],
    },
    {
        path: "/pygrunn",
        redirect: "/pygrunn/home",
        component: () => import("@/layouts/PyGrunnLayout.vue"),
        meta: {
            auth: AuthStatus.HAS_ROLES,
        },
        children: [
            {
                path: "home",
                meta: {
                    auth: AuthStatus.ACTIVE_ROLE,
                },
                component: () => import("@/views/pygrunn/HomePage.vue"),
                beforeEnter: async (to, from, next) => {
                    const roles = await getTopLevelRoles()
                    const pyGrunnRole = roles.find((r) => r.organisation.domain === "org_pygrunn")
                    setRole(pyGrunnRole)
                    next()
                },
            },
            {
                path: ":domainName/:productName/:processName/",
                component: () => import("@/views/manage/process/ProcessHandler.vue"),
                beforeEnter: async (to, from, next) => {
                    await guard(to, from, next)([resolveRoleFromUrl])
                },
            },
        ],
    },
    {
        path: "/error",
        component: () => import("@/views/ErrorPage.vue"),
        meta: {
            auth: AuthStatus.UNAUTHENTICATED,
        },
    },
    {
        path: "/login",
        component: () => "",
        beforeEnter: async () => {
            await login()
        },
        meta: {
            auth: AuthStatus.UNAUTHENTICATED,
        },
    },
    {
        path: "/verify_email",
        component: () => import("@/views/VerifyEmail.vue"),
        meta: {
            auth: AuthStatus.UNAUTHENTICATED,
        },
    },
    {
        path: "/unorganised",
        component: () => import("@/views/UnorganisedUser.vue"),
        meta: {
            auth: AuthStatus.UNAUTHENTICATED,
        },
    },
    {
        path: "/logout",
        component: () => "",
        beforeEnter: async () => {
            await logout()
        },
        meta: {
            auth: AuthStatus.UNAUTHENTICATED,
        },
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

/**
 * Remove code and state from query if found.
 *
 * NOTE: Only returns something when the route is modified!
 * This way it can be used as a parameter to next(), since if you pass
 * an unchanged route there, it becomes a redirect loop.
 */
const removeCodeAndStateFromQuery = (route: RouteLocationNormalized) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    const { code, state, ...query } = route.query
    if (JSON.stringify(route.query) !== JSON.stringify(query)) {
        route.query = query
        return route
    }
}

router.beforeEach(async (to, _from, next) => {
    const requiredAuth = (to.meta?.auth as AuthStatus) || AuthStatus.UNAUTHENTICATED
    if (AuthStatus.UNAUTHENTICATED === requiredAuth) {
        return next(removeCodeAndStateFromQuery(to))
    }

    // Just continue if your authStatus is sufficient enough
    if (requiredAuth <= authStatus.value) {
        return next(removeCodeAndStateFromQuery(to))
    }
    if (authStatus.value <= AuthStatus.UNAUTHENTICATED) {
        await login()
        return next(removeCodeAndStateFromQuery(to))
    }
    if (authStatus.value <= AuthStatus.NOT_VERIFIED) {
        return next({ path: "/verify_email" })
    }
    if (authStatus.value <= AuthStatus.NO_ROLES) {
        return next({ path: "/unorganised" })
    }

    // Guess role if required
    if (requiredAuth >= AuthStatus.ACTIVE_ROLE && !getRole()) {
        const roles = await getTopLevelRoles()
        setRole(roles[0])
    }
    return next(removeCodeAndStateFromQuery(to))
})

// Set HTML Title
router.afterEach((to, _from) => {
    nextTick(() => {
        // Set title, fall back to default if no function set
        try {
            document.title = to.meta?.title(to)
        } catch {
            document.title = DEFAULT_TITLE
        }
    })
})

/**
 * This function will navigate to the previous page, if any.
 * Otherwise will navigate to /manage
 */
export async function navigateBackOrHome() {
    if (window.history.state.back === null) {
        return await router.push("/manage")
    }

    return router.back()
}

function normalizeProcessName(processName: string) {
    return processName.split("_").map(capitalize).join(" ")
}

export default router
