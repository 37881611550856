import { Config } from "tailwindcss"

const config: Config = {
    content: ["./index.html", "./src/**/*.{vue,js,ts,jsx,tsx}"],
    theme: {
        extend: {
            screens: {
                mobile: { max: "767px" },
                tablet: "768px",
                desktop: "1024px",
            },
        },
    },
    variants: {
        extend: {},
    },
    plugins: [],
    safelist: ["italic", "font-bold"],
}

export default config
